(function ($) {
  $(".form form").each(function () {
    var that = $(this);

    $('[name="inscription[echantillon]"]').on("change", function () {
      var addressFieldset = that.find("#form-address");

      if ($(this).val() == "Oui") {
        addressFieldset.addClass("show");
        addressFieldset.find("input").prop('required', true);
      } else {
        addressFieldset.removeClass("show");
        addressFieldset.find("input").prop('required', false);
      }
    });
  });
})(jQuery);
